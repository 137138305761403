/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StepsWrapper } from '../../CreateEvent/style';
// import { ImageUrl } from '../../../utils/constants';
import {Steps} from './styles';
import { withTranslation } from 'react-i18next';

class StepsHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
    }
  }

  render() {
    const { stepCount, heading } = this.props;
    return (
      <StepsWrapper>
        {heading.map((step, index) =>
          (<Steps key={index} activeColor={index+1<=stepCount?true:false}>
            <div className="wrapper">
              <div className="number">{step.number}</div>
              <div className="heading">{this.props.t(step.name)}</div>
            </div>
            {step.showArrow && <div className="arrow">
              <div className="active">
                {index+1<=stepCount?
                  <img src={"/public/images/stepArrowUpdated.svg"} />:
                  <img src={"/public/images/stepArrowUpdated.svg"} />}
              </div>
            </div>}
          </Steps>))}
      </StepsWrapper>
    );
  }
}

StepsHeading.propTypes = {
  history: PropTypes.object,
  stepCount: PropTypes.number,
  type: PropTypes.string,
  heading: PropTypes.array,
  t: PropTypes.func
};

export default ((withTranslation())(StepsHeading));