/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage
} from "../../../utils/methods";
import { getAllCompanies, saveChallengeData, createActivityChallengeV2 } from "../../../redux/actions/challengeActions";
import {
  getUserCompanyDetails,
  allowChallengeEventForSpouseDependent,
} from "../../../redux/actions/userProfileActions";
import { imageErrorMessage } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  // UploadPhotoContainer,
  // RemoveImage,
  // InputImage,
  ButtonContainer,
  Border,
  EditorContainer
  // EditorContainer
} from "../../CreateEvent/style";
import StepsHeading from "./steps";
import RadioButton from "../../CustomRadioButton";
import { Main, ToolTip } from "../../AdminTeamCreation/styles";
import { getSurveyLibraries } from "../../../redux/actions";
// import Editor from "../../CKEditor";
import HoverButton from "../../common/HoverButtonV2";
import CommonButton from "../../common/CommonButton/CommonButton";
import { Arrows, closeIcon, CommonCloseIcon, NextArrows, UploadIcon } from '../../../utils/icons';
// import {DropdownArrow} from "../../AdminTeamCreation/styles";
import {CustomInput, SurvivorAverage, SurvivorChallengeSection, /*CustomMenuItem,*/ TitleContainer, /*ActivityDropdown,*/ FieldTitle,/* CustomTextArea, ChallengeTypeDropdown,ActivityChallengeDropdown, ChallengePointDropdown, ChallengeSurveyDropdown*/} from './styles';
import moment from 'moment';
import { challengeTierPoints } from "../../../../mockData";
// import { Dropdown } from "react-bootstrap";
import CommonImageUploader from "../../common/CommonImageUploader/CommonImageUploader";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import Editor from "../../CKEditor";
// import { challengeTierPoints } from '../../../../mockData.json';
class CreateActivityChallenge extends Component {
  constructor(props) {
    super();
    const { isGroupChallenge, isSurvivorChallenge } = props;
    let updatedTiers = challengeTierPoints;
    this.state = {
      step: 1,
      surveyArrow: false,
      activityArrow: false,
      challengeType: false,
      challengeTypeArrow:false,
      pointsArrow: false,
      buttonClickScrollTop: false,
      menuIsOpen: true,
      title: '',
      description: '',
      imageName: '',
      activityType: '',
      weeklyIncrement: null,
      survivorAverage: null,
      amigosToInvite: [],
      openDateTimePicker: '',
      imgSrc: '',
      activityChallengeType: '',
      activityChallengeValue:"",
      survivorChallenge: isSurvivorChallenge,
      groupChallenge: isGroupChallenge,
      imageUpdated: 0,
      challengePointKey: null,
      companiesPermission: false,
      locationPermission: false,
      selectedOption: 1,
      surveyId: '',
      surveyType: '',
      dailyGoal: 0,
      selectedDailyGoalOption: 1,
      is_team_challenge:0,
      challengePointValue: null,
      challengePoints: updatedTiers,
      buttonStatus: false,
      selectedChallengeType: null,
      selectedActivityType: null,
      selectedChallengePoint: '',
      selectedSurvey: null,
      // dropdownOpen:false,
      so: false,
    };
    this.onSelectPoints = this.onSelectPoints.bind(this);
  }

  componentDidMount() {
    this.props.getSurveyLibraries();
  }

  activityChallengeTypes = [
    {"key": "Classic challenge", "value": 'Classic challenge'},
    {"key": "Survivor challenge", "value": 'Survivor challenge'},
    {"key": "Group Challenge", "value": 'Group Challenge'},
    // {"key": "Group Challenge", "value": 'Team Challenge'}
  ]

  activityTypesItems= [
    {"key": "Total Distance", "value": 'Total Distance'},
    {"key": "Total Steps", "value": 'Total Steps'},
    {"key": "Total Calories", "value": 'Total Calories'}
  ]

  groupActivityTypesItems= [
    {"key": "Total Group Distance", "value": 'Total Group Distance'},
    {"key": "Total Group Steps", "value": 'Total Group Steps'},
    {"key": "Total Group Calories", "value": 'Total Group Calories'}
  ]

  stepsHeadingEvent = [
    {
      name: 'About Challenge',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Challenge',
      showArrow: true,
      number: 2
    },
    {
      name: 'Create Challenge',
      showArrow: false,
      number: 3
    }
  ]; 

  onSubmit = () => {
    const { title, date, description, imgSrc, endDate, activityType, challengePointValue, challengeMode, weeklyIncrement,
      survivorAverage, imageName, companies, challengeLength, survivorChallenge, groupChallenge, emailOption, surveyId, selectedOption, dailyGoal, selectedDailyGoalOption, is_team_challenge, teams } = this.state;
    const { saveChallengeData, createActivityChallenge, createLibrary, editLibrary, history } = this.props;
    let survivorEndDate = moment(moment(date).add((challengeLength * 7) - 1, 'days')._d).format('MM/DD/YYYY');
    const challengeType = survivorChallenge ? 'survivor' : groupChallenge ? 'new_group' : 'group';
    this.setState({ buttonStatus: true });
    const challenge = {
      title,
      companies: companies,
      body: description,
      image: imgSrc,
      event_type: groupChallenge ? activityType === 'Total Group Steps' ? 'Total Steps' : activityType === 'Total Group Calories' ? 'Total Calories' : 'Total Distance' : activityType,
      end_date: createLibrary || editLibrary ? null : (survivorChallenge || groupChallenge) ? moment(survivorEndDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss") : moment(endDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss"),
      start_date: createLibrary || editLibrary ? null : moment(date).format('YYYY-MM-DD') + ' ' + moment('12:00:00 AM', "hh:mm:ss a").format("HH:mm:ss"),
      survivor_average: survivorAverage,
      weekly_increment: survivorChallenge ? weeklyIncrement : null,
      challenge_point: challengePointValue,
      challenge_mode: survivorChallenge ? challengeMode : '',
      challenge_type: challengeType,
      isSurvivorChallenge: survivorChallenge,
      isGroupChallenge: groupChallenge,
      ...emailOption,
      survey_id: selectedOption === 1 ? null : surveyId,
      daily_goal: selectedDailyGoalOption === 1 ? null : dailyGoal,
      is_team_challenge:is_team_challenge, 
      teams:teams
    };
    saveChallengeData({ ...challenge, imageName });
    createLibrary && createActivityChallenge(challenge, history.location.pathname.includes("/company/challenges/")?'/company/challenges/categories':'/challenges/categories');
  };

  renderHeading = () => (
    <ChallengeHeading color="#005C87">
      <ToolTip
        margin={"auto 15px auto 0"}
        activeValue={"transparent"}
        height="40px"
        width="55px"
      >
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath= {CommonCloseIcon()}
          onClick={() => window.history.back()}
          // backgroundColorActive="007AB1"
          activeColor={"#007AB1"}
          fillOpacity={1}
          backgroundColor="#005C871A"
        />
      </ToolTip>
      {this.props.t("Create the Challenge")}
    </ChallengeHeading>
  );

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeDescription = (e) => {
    const newContent = e.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };



  handleImageChange = (imgSrc) => {
    this.setState({ imgSrc });
  };

  handleRemoveImage = () => {
    this.setState({ imgSrc: "" });
  };


  onSelectPoints(name) {
    this.setState({
      selectedChallengePoint: name.value,
      challengePointKey: name.key,
      challengePointValue: name.value,
    });
  }

  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById('create-challenge-file').value.split("\\");
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: array[array.length - 1],
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage,
                imageUpdated: this.props.editLibrary ? 1 : 0
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('create-challenge-file').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('create-challenge-file').value = '';
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

 

  stepOneForm = () => {
    const {
      title,
      description,
      step,
      imgSrc
    } = this.state;
    const { t } = this.props;

    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Challenge Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color="#005C87">{this.props.t("Title of the challenge")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={t("Write title here...")}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    bgColor="#ffffff"
                    pColor={"rgba(0, 92, 135, 0.3)"}
                    border="1px solid rgba(0, 122, 177, 0.30)"
                    color="#005C87"
                    isPadding={" 0px 20px 0px 12px"}
                    isplaceholder={true}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>

                <StyledLabel color="#005C87" margin="0px">{this.props.t("Challenge image")}{<span>*</span>}</StyledLabel>
                <CommonImageUploader
                  imgSrc={imgSrc}
                  t={t}
                  closeIcon={closeIcon}
                  UploadIcon={UploadIcon}
                  onImageChange={this.handleImageChange}
                  onRemoveImage={this.handleRemoveImage}
                />
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                {/* previous image component          */}
                {/* <UploadPhotoContainer border bgColor>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <img alt="profilre-img" src={this.state.imgSrc}></img>
                    ) : (
                      <div className="uploadImage">
                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1}
                          bgColor
                          style={{width:"unset"}}
                        >
                          <i className="addImg" >{this.props.t("Upload Photo")}</i>
                          <input
                            id="create-challenge-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                    {this.state.imgSrc.length === 0 ? null : (
                      <RemoveImage onClick={this.removePhoto} bgColor="#F4AAA9">
                        {this.props.t("Remove Image")}
                      </RemoveImage>
                    )}
                  </div>
                </UploadPhotoContainer> */}
                
                {/* <InputContainer>
                  <StyledLabel margin={"20px"} color="#005C87">{this.props.t("About the Challenge")}{<span>*</span>}</StyledLabel>
                  <CustomTextArea
                    placeholder={this.props.t("Write Descirption about the Challenge")}
                    rows="8"
                    name="description"
                    value={description}
                    onChange={this.onChangeInput}
                    data-gramm_editor="false"
                    type="text"
                  />
                </InputContainer> */}

                <InputContainer>
                  <StyledLabel style={{marginTop:"20px"}} color={"#005c87"}>{this.props.t("About the challenge")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };


  handleSelectChallengeType = (name) => {
    this.setState({
      activityChallengeType: name.value,
      survivorChallenge: name.value === "Survivor challenge",
      groupChallenge: name.value === "Group Challenge",
      activityType: name.value === "Group Challenge" ? "Total Group Distance" : "Total Distance",
      challengePointKey: this.state.challengePointKey,
      activityChallengeValue: name.key,
      selectedChallengeType:name.value,
    })
   
  };


  onSelectActivityChallenge = (name) => {
    this.setState({ 
      selectedActivityType: name.value,
      activityTypes:name.key,
      activityType:name.value });
  };



  

  // onSelect = (name, value, key) => {
  //   // const { challengePointKey } = this.state;
  //   (name === "activityChallengeType") ?
  //     this.setState({
  //       [name]: value,
  //       survivorChallenge: value === "Survivor challenge",
  //       groupChallenge: value === "Group Challenge",
  //       activityType: value === "Group Challenge" ? "Total Group Distance" : "Total Distance",
  //       challengePointKey: this.state.challengePointKey,
  //       activityChallengeValue: key
  //     }) :
  //     this.setState({
  //       [name]: value,
  //       // challengePointKey: key != '' ? key : challengePointKey
  //     });
  //   if(key==="Team Challenge"){
  //     this.setState({
  //       is_team_challenge: 1
  //     });
  //   }
  // };

  onSelectPoint = (name, value, key ) => {
    this.setState({
      [name]: value,
      challengePointKey: key
    });
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  stepTwoForm = () => {
    const {
      step,
      /*activityChallengeType,*/
      groupChallenge,
      activityType,
      survivorChallenge,
      survivorAverage,
      weeklyIncrement,
      selectedChallengeType,
      challengePointValue,
      challengePointKey,
      // challengeTypeArrow,
      // pointsArrow,
      // challengePointKey,
      // challengePointValue,
      challengePoints,
      // activityChallengeValue
    } = this.state;
    const { t } = this.props;
    // const { selectedChallengeType , challengePointValue,challengePointKey } = this.state;
    const activityTypes = groupChallenge ? this.groupActivityTypesItems : this.activityTypesItems;
    return (
      <Layout height="1000px">
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent} />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("How would you like to customize your challenge?")}</div>
              </div>
              <div className="formBody">
               



                <TitleContainer widthValue="70%" height="auto" padding="15px 0px 0px 0px">
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={this.props.t("Challenge types")}
                    isRequired={true}
                    dropdownOpen={this.state.dropdownOpen}
                    placeholder={this.props.t("Select challenge type")}
                    title={selectedChallengeType}
                    id="dropdown-recurring"
                    onClick={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}
                    data={this.activityChallengeTypes}
                    // onSelect={this.onSelectChallengeType}
                    onSelect={this.handleSelectChallengeType}
                    active={this.state.selectedChallengeType}
                    onSelectParameter={["activityChallengeType", "value", "key",]}
                    valueIndex={1}
                    itemValue={true}
                    labelMargin={"0px"}
                  />
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                </TitleContainer>
                
                

                {/* Previous component */}
                {/* <FieldTitle>
                  {this.props.t("Activity Challenge")}
                  {<span className>*</span>}
                </FieldTitle> */}
                {/* <DropdownArrow
                  alt="image"
                  top={this.state.activityArrow ? "70px" : "67px"}
                  // src={
                  //   this.state.activityArrow
                  //     ? "/public/images/teamDownArrow.svg"
                  //     : "/public/images/dropdown_arrow.svg"
                  // }
                  src={ "/public/images/teamDownArrow.svg"}
                /> */}

                {/* New Activity challenge dropdown */}
                {/* <ActivityChallengeDropdown
                  notActive={!this.state.selectedActivityType}
                  onSelect={(index) => {
                    this.onSelect(
                      "activityType",
                      activityTypes[index].key,
                      activityTypes[index].value
                    );
                    this.setState({
                      selectedActivityType:
                        activityTypes[index].value,
                    });
                  }}
                >
                  <Dropdown.Toggle>
                    {this.state.selectedActivityType
                      ? this.props.t(this.state.selectedActivityType)
                      : this.props.t("Select Activity Type")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {activityTypes &&
                      activityTypes.length > 0 &&
                      activityTypes.map((list, index) =>  (
                        <Dropdown.Item eventKey={index} key={index}>
                          {this.props.t(list.value)}
                          <img
                            alt="profilre-img"
                            src={
                              "/public/images/ChallengeDetailsScreen/completeCheck.png"
                            }
                          ></img>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </ActivityChallengeDropdown> */}
                {/* ******************************** */}
              

                
                <TitleContainer widthValue="70%" height="auto" padding="20px 0px 0px 0px">
                 
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={this.props.t("Activity challenge")}
                    isRequired={true}
                    data={activityTypes}
                    onSelect={this.onSelectActivityChallenge}
                    onSelectParameter={["activityType", "key", "value"]}
                    active={this.state.selectedActivityType}
                    placeholder={this.props.t("Select activity type")}
                    title={this.state.selectedActivityType}
                    itemValue={true}
                    valueIndex={2}
                    t={this.props.t}
                    labelMargin={"0px"}
                    onClick={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}
                  />
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 5px 0px'}}/>
                </TitleContainer>


                <SurvivorChallengeSection>
                  {survivorChallenge && (
                    <SurvivorAverage isMargin={"15px"} isHeight={"50px"}>
                      <FieldTitle>
                        {groupChallenge ? t("Team goal") : t("Week 1 goal")}{<span>*</span>}
                      </FieldTitle>
                      <CustomInput
                        isHeight={"50px"}
                        isFont={"rubik"}
                        isRadius={"4px"}
                        isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                        isBgColor={"white"}
                        type="number"
                        name="survivorAverage"
                        isPlaceholder={"rgba(0, 92, 135, 0.30)"}
                        onChange={this.onChangeInput}
                        value={
                          activityType.includes("Distance")
                            ? parseFloat(survivorAverage)
                            : parseInt(survivorAverage,10)
                        }
                        pattern="[0-9]*"
                        min="0"
                        onKeyPress={(e) => this.isNumberKey(e)}
                        placeholder={
                          activityType === "Total Distance"
                            ? this.props.t("Enter E.g. 20 Miles")
                            : activityType === "Total Calories"
                              ? this.props.t("Enter E.g. 100 Calories")
                              : this.props.t("Enter E.g. 1000 Steps")
                        }
                      />
                    </SurvivorAverage>
                    
                  )}
                
                  {survivorChallenge && (
                    <SurvivorAverage  isMargin={"15px"} isHeight={"50px"}>
                      <FieldTitle>{t("Weekly increment")}{<span>*</span>}</FieldTitle>
                      <CustomInput
                        isHeight={"50px"}
                        isBgColor={"white"}
                        isFont={"rubik"}
                        isRadius={"4px"}
                        isPlaceholder={"rgba(0, 92, 135, 0.30)"}
                        isBorder={"1px solid   rgba(0, 122, 177, 0.30)"}
                        type="number"
                        name="weeklyIncrement"
                        value={weeklyIncrement}
                        onChange={this.onChangeInput}
                        pattern="[0-9]*"
                        min="0"
                        onKeyPress={(e) => this.isNumberKey(e)}
                        placeholder={
                          activityType === "Total Steps"
                            ? this.props.t("Enter E.g. 500 Steps")
                            : activityType === "Total Calories"
                              ? this.props.t("Enter E.g. 50 Calories")
                              : this.props.t("Enter E.g. 5 Miles")
                        }
                      />
                    </SurvivorAverage>
                    
                  )}
                  {groupChallenge && (
                    <SurvivorAverage width="100%" isMargin={"15px"}  isHeight={"50px"}>
                      <FieldTitle>{t("Group goal")}{<span>*</span>}</FieldTitle>
                      <CustomInput
                        isHeight={"50px"}
                        isFont={"rubik"}
                        isRadius={"4px"}
                        isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                        isBgColor={"white"}
                        type="number"
                        name="survivorAverage"
                        onChange={this.onChangeInput}
                        value={
                          activityType.includes("Distance")
                            ? parseFloat(survivorAverage)
                            : parseInt(survivorAverage,10)
                        }
                        pattern="[0-9]*"
                        min="0"
                        onKeyPress={(e) => this.isNumberKey(e)}
                        placeholder={
                          activityType === "Total Steps" ||
                          activityType == "Total Group Steps"
                            ? this.props.t("Enter E.g. 500 Steps")
                            : activityType === "Total Calories" ||
                              activityType === "Total Group Calories"
                              ? this.props.t("Enter E.g. 30 Calories")
                              : this.props.t("Enter E.g. 25 Miles")
                        }
                      />
                      <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 5px 0px'}}/>
                    </SurvivorAverage>
                  )}
                </SurvivorChallengeSection>
                {/* <FieldTitle>
                    {this.props.t("Challenge Point Value")}
                    {<span className>*</span>}
                  </FieldTitle> */}
                {/* <DropdownArrow
                    alt="image"
                    top={pointsArrow ? "70px" : "67px"}
                    // src={
                    //   pointsArrow
                    //     ? "/public/images/teamDownArrow.svg"
                    //     : "/public/images/dropdown_arrow.svg"
                    // }
                    src={ "/public/images/teamDownArrow.svg"}
                  /> */}

                {/* New challenge point dropdown */}
                {/* <ChallengePointDropdown
                    isWidth={"100%"}
                    // notActive={!this.state.selectedChallengePoint}
                    onSelect={(index) => {
                      this.onSelectPoint(
                        "challengePointValue",
                        challengePoints[index].value,
                        challengePoints[index].key,
                      )
                      this.setState({
                        selectedChallengePoint:
                        challengePoints[index].key,
                      });
                    }}
                  >
                    <Dropdown.Toggle>
                      {this.state.selectedChallengePoint
                        ? this.props.t(this.state.selectedChallengePoint)
                        : this.props.t("Select Point Value")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {challengePoints &&
                      challengePoints.length > 0 &&
                      challengePoints.map((list, index) =>  (
                        <Dropdown.Item eventKey={index} key={index}>
                          {t(list.key)}{" "}
                          <img
                            alt="profilre-img"
                            src={
                              "/public/images/ChallengeDetailsScreen/completeCheck.png"
                            }
                          ></img>
                        </Dropdown.Item>
                      )
                      )}
                    </Dropdown.Menu>
                  </ChallengePointDropdown> */}

                {survivorChallenge ? <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 5px 0px'}}/>: null}
                <TitleContainer widthValue="70%" height="275px">
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={t("Challenge point value")}
                    isRequired={true}
                    dropdownOpen={this.state.so}
                    placeholder={t("Select point value")}
                    title={challengePointKey}
                    id="dropdown-recurring"
                    onClick={() => this.setState({ so: !this.state.so })}
                    data={challengePoints}
                    onSelect={this.onSelectPoints}
                    active={challengePointValue}
                    onSelectParameter={["value", "key"]}
                    // valueString={"Points"}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                    t={t}
                  />
                </TitleContainer>
              </div> 
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  toggleRadioButton = (value) => {
    this.setState({ selectedOption: value }, () => { });
  };

  // onSelectSurveyType = (name, value, id) => {
  //   this.setState({
  //     [name]: value,
  //     surveyId: id
  //   });
  // };


  onSelectSurveyType = (selectedItem) => {
    this.setState({
      selectedSurvey: selectedItem.survey_name,
      surveyType: selectedItem.survey_name,
      surveyId: selectedItem.id,
    });
  };


  stepThreeForm = () => {
    const {
      step,
      selectedOption,
      // surveyType
    } = this.state;
    const {surveyLibraries, t} = this.props;
    const { selectedSurvey } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent} />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">
                  {this.props.t("Set the survey or create the challenge")}
                </div>
              </div>
              <div className="formBody">
                <StyledLabel color="#005C87">
                  {this.props.t("Would you like to add the survey")} ?
                </StyledLabel>
                <div className="radioButtonDiv">
                  <span onClick={() => this.toggleRadioButton(0)}>
                    <RadioButton
                      id="1"
                      handler={this.toggleRadioButton}
                      value={0}
                      isChecked={selectedOption === 0}
                      label={this.props.t("Yes")}
                      challengeCreate={true}
                    />
                  </span>
                  <span onClick={() => this.toggleRadioButton(1)}>
                    <RadioButton
                      id="2"
                      handler={this.toggleRadioButton}
                      value={1}
                      isChecked={selectedOption === 1}
                      label={this.props.t("No")}
                      challengeCreate={true}
                    />
                  </span>
                </div>
                {/* <FieldTitle>
                      {this.props.t("List of Surveys")}
                      {<span className>*</span>}
                    </FieldTitle> */}
                {/* <DropdownArrow
                      alt="image"
                      top={"67px"}
                      // src={
                      //   this.state.surveyArrow
                      //     ? "/public/images/teamDownArrow.svg"
                      //     : "/public/images/dropdown_arrow.svg"
                      // }
                      src={ "/public/images/teamDownArrow.svg"}
                    /> */}

                {/* New Select surevy dropdown
                    <ChallengeSurveyDropdown
                      // notActive={!this.state.selectedSurvey}
                      onSelect={(index) => {
                        this.onSelectSurveyType(
                          "surveyType",
                          surveyLibraries[index].survey_name,
                          surveyLibraries[index].id
                        );
                        this.setState({
                          selectedSurvey: surveyLibraries[index].survey_name,
                        });
                      }}
                    >
                      <Dropdown.Toggle>{this.state.selectedSurvey
                        ? this.props.t(this.state.selectedSurvey)
                        :this.props.t("Select Point Value")}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {surveyLibraries &&
                          surveyLibraries.length > 0 &&
                          surveyLibraries.map((list, index) => (
                            <Dropdown.Item eventKey={index} key={index}>
                              {t(list.survey_name)}{" "}
                              <img
                                alt="profilre-img"
                                src={
                                  "/public/images/ChallengeDetailsScreen/completeCheck.png"
                                }
                              ></img>
                            </Dropdown.Item>
                          )
                          )}
                      </Dropdown.Menu>
                    </ChallengeSurveyDropdown> */}
                 
                {selectedOption === 0 ? (
                  <TitleContainer widthValue="70%" height="auto">
                    <CommonDropdown
                      tileContainerStyle={{ width: "100%", padding: "0px 0px 20px 0px" }}
                      dropdownStyle={{ top: "90px" }}
                      labelText={t("List of surveys")}
                      isRequired={true}
                      dropdownOpen={this.state.so}
                      placeholder={t("Select survey")}
                      title={selectedSurvey}
                      id="dropdown-survey"
                      onClick={() => this.setState({ so: !this.state.so })}
                      data={surveyLibraries}
                      onSelect={this.onSelectSurveyType}
                      active={selectedSurvey}
                      onSelectParameter={["survey_name", "survey_name", "id"]}
                      // valueString={"Points"}
                      itemValue={true}
                      valueIndex={0}
                      labelMargin={"0px"}
                      t={t}
                    />
                  </TitleContainer>
                ) : null}


              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  DropdownIndicator = () => (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
        fill="#0D4270"
      />
    </svg>
  );

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true, buttonStatus:false });
  };

  render() {
    const { step, buttonStatus, title, imgSrc, description, activityType, activityChallengeType, survivorChallenge, groupChallenge, weeklyIncrement, survivorAverage,challengePointValue} = this.state;
    return (
      <React.Fragment>
        {step === 1?this.stepOneForm(): step === 2? this.stepTwoForm(): step === 3 && this.stepThreeForm()}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Next")}
                disabled={!title||!imgSrc|| !description}
                styles={{color:"#007AB1"}}
                nextIcon={NextArrows("white")}
              />
            </Main>
          </ButtonContainer>
        ) : step === 2 ? (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px", display:"flex",justifyContent:"center",alignItems:"center" }} 
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={!activityType || !activityChallengeType || (survivorChallenge && (!weeklyIncrement || !survivorAverage)) || (groupChallenge && !survivorAverage) || (!challengePointValue && challengePointValue != 0)}
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
              />
            </div>
          </ButtonContainer>
        ) : (
          step === 3 && (
            <ButtonContainer>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                  onClick={() => this.updateStep(2)}
                  title={this.props.t("Go Back")}
                  icon={Arrows()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  disabled={buttonStatus || (!this.state.selectedSurvey && this.state.selectedOption == 0)}
                  styles={{color:"#007AB1", marginAuto:'auto'}}
                  onClick={!buttonStatus ? () => this.onSubmit():""}
                  title={this.props.t("Create Challenge")}
                />
              </div>
            </ButtonContainer>
          )
        )}
      </React.Fragment>
    );
  }
}

CreateActivityChallenge.propTypes = {
  isSurvivorChallenge: PropTypes.bool.isRequired,
  createChallenge: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  MandatoryFields: PropTypes.array.isRequired,
  survivorChallengeData: PropTypes.object,
  saveChallengeData: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
  role: PropTypes.string,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  isGroupChallenge: PropTypes.bool,
  activityChallengeById: PropTypes.bool,
  createActivityChallenge: PropTypes.func,
  createLibrary: PropTypes.bool,
  editChallengeLibrary: PropTypes.func,
  editLibrary: PropTypes.bool,
  userPermissions: PropTypes.array,
  surveyLibraries: PropTypes.array,
  getSurveyLibraries: PropTypes.func,
  t: PropTypes.func,
  fetchCompanyTeamUsers:PropTypes.func
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  userPermissions: state.profileData.userPermissions,
  surveyLibraries: state.surveys.surveyLibraries,
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeData: (data) => dispatch(saveChallengeData(data)),
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  allowChallengeEventForSpouseDependent: () => dispatch(allowChallengeEventForSpouseDependent),
  editChallengeLibrary: (challenge) => dispatch(editChallengeLibrary(challenge)),
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
  fetchCompanyTeamUsers: (companyId) => dispatch(fetchCompanyTeamUsers(companyId)),
  createActivityChallenge: (challenge, path) => dispatch(createActivityChallengeV2(challenge, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateActivityChallenge));
